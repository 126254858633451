<template>
  <div class="container">
    <div class="row campaigndiv mt-5 px-md-5">
      <div class="col-sm-5">
        <div class="text-center campaignbox">
          <img
            :src="selectedpromotion?.img"
            class="img-fluid rounded-circle"
            alt="Banner"
          />
        </div>
      </div>
      <div class="col-sm-7">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Home' }">Home</router-link>
            </li>
            <li class="breadcrumb-item" >
              {{ selectedpromotion?.name }}
            </li>
          </ol>
        </nav>
        <div class="text-left">
          <h1 class="text-uppercase fw-900 text-pg-blue mb-1 h2">
            {{ selectedpromotion?.name }}
          </h1>
          <h2 class="text-pg-blue fw-bold h5 mb-4" v-html="selectedpromotion?.desc">
    
          </h2>
          <router-link
            v-if="isRedeemAvailable"
            class="btn-link mb-3"
            :to="{
              name: 'Promotion Redeem',
              params: { id: selectedpromotion?.id },
            }"
          >
            REDEEM
          </router-link>
          <p v-else-if="hasCampaignEnded" class="text-danger fw-bold">
            We’re sorry, but this program has ended.
          </p>
          <p>
            <small
              >*Terms and conditions apply.
              <a :href="selectedpromotion?.termslink" target="_blank">Review for details</a></small
            >
          </p>
        </div>
      </div>
    </div>
    <div class="col-sm-12 mb-5">
      <small v-html="selectedpromotion?.disclaimer"></small>
    </div>
  </div>

  <div class="bg-blue-top-curve-desktop"></div>
  <div class="bg-blue">
    <div class="mobile-curve">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2 class="text-center text-pg-blue fw-bold">
            How It Works
          </h2>
        </div>
      </div>
    </div>

  <HowitworksSteps></HowitworksSteps>
  </div>
      <!-- Brands Section -->
      <div v-if="selectedpromotion?.brands && selectedpromotion.brands.length">
      <div class="container">
        <div class="row">
          <h2 class="text-center text-pg-blue fw-bold mt-5 mb-5">
              
              <p class="text-center">Shop products from these participating brands</p>
            </h2>
        
          <div class="d-flex flex-wrap align-items-center justify-content-center brand-box">
            <div class="brandtitle"><p class="text-uppercase d-block text-center">participating brands</p></div>
            <a
              v-for="(brand, index) in selectedpromotion.brands"
              :key="index"
              :href="brand.url"
              target="_blank"
              rel="noreferrer noopener"
              class="brand block p-4 text-center"
            >
              <img
                :src="brand.logo"
                :alt="brand.name + ' Logo'"
                class="mx-auto"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-blue-bottom-curve-desktop"></div>
  <!-- Promotion Faq section -->
  <div v-if="selectedpromotion?.faqs && selectedpromotion.faqs.length">
    <div class="container">
      <div class="row faqs mx-auto mb-5 mt-4">
        <div class="col-md-12">
          <h2 class="text-center text-pg-blue fw-bold mt-5 mb-5">
            
            <p class="text-center">Program FAQs</p>
          </h2>
        </div>
        <div class="faqwrapper">
        <div class="faqdiv mb-4" v-for="faq in selectedpromotion.faqs" :key="faq.question">
          <h6 class="pb-2 fw-bold">
            {{ faq.question }}
          </h6>
          <div class="copy">
            <p v-html="faq.answer"></p>
           
          </div>
        </div></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
       <h5 class="fw-bold text-pg-blue mb-3 text-center mb-5 helpemail">Got a question we didn't answer? <br>Email <a href="mailto:help@pglocalvalues.com">help@pglocalvalues.com</a></h5>
      </div>
    </div>
  </div>
</template>

<script>
import HowitworksSteps from './HowItWorks/HowitworksSteps.vue';
import jsonMixin from '../jsonMixin';

export default {
  components: { HowitworksSteps },
  mixins: [jsonMixin],
  props: ['id'],
  data() {
    return {
      selectedpromotion: null,
    };
  }, 
  computed: {
    isRedeemAvailable() {
      if (!this.selectedpromotion || !this.selectedpromotion.enddate) {
        return false; // If no end date is provided, assume not available
      }
      const currentDate = new Date();
      const promoEndDate = new Date(this.selectedpromotion.enddate);
      return currentDate <= promoEndDate; // Check if the current date is before or on the end date
    },
    hasCampaignEnded() {
      if (!this.selectedpromotion || !this.selectedpromotion.enddate) {
        return false; // If no end date is provided, assume campaign hasn't ended
      }
      const currentDate = new Date();
      const promoEndDate = new Date(this.selectedpromotion.enddate);
      return currentDate > promoEndDate; // Check if the current date is after the end date
    },
  },
};

</script>

<style scoped>
.helpemail a{color: #003da6; text-decoration: none;}
.helpemail a:hover{color: #003da6; text-decoration: underline;}
.brand-box{background-color: #fff;margin-bottom: 50px;border: 1px solid #bdbdbd;padding: 30px;}
.brand {
  width: 9%;
    padding: 10px 10px !important;
    margin: 5px;
}
.p-4 {
  padding: 1rem;
}
.brand img {
  height: auto;
  max-width: 100%;
}
.breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: 0.3rem;
  list-style: none;
  display: flex;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: '>';
  padding: 0 7px;
}
.campaigndiv {
  display: flex;
  justify-content: center;
  align-items: center;margin-bottom: 50px;
}
.campaignbox img {
    max-width: 260px;
    height: 260px;
    margin: 0 auto;
    margin-bottom: 20px;
    border: 5px solid #16a2dd;
}
.brandtitle {
    display: block;
    width: 100%;
}
.brandtitle p{color: #545454;font-size: 1.3rem;  text-align:center;
  position: relative;
    z-index: 1;
    overflow: hidden;}
.brandtitle p:after,.brandtitle p:before {
  position: absolute;
    top: 51%;
    overflow: hidden;
    width: 48%;
    height: 2px;
    content: '\a0';
    background-color: #cccccc;
    margin-left: 2%;
}
.brandtitle p::before{  margin-left: -50%;
  text-align: right;}
@media screen and (max-width: 575px) {
  .brand {
    width: 18%;
    padding: 0px !important;
}
.brand-box[data-v-1a70372c] {
  background-color: #fff;
        margin-bottom: 0px;
        border: none;
        padding: 15px;
}
  .bg-blue-top-curve::before {
   display: none;
  }
  .campaignbox img {
    max-width: 100%;
    height: auto;
}
  .campaigndiv {
    text-align: center;
  }
  .breadcrumb{
        display: flex;
        justify-content: center;
        font-size: 12px;
    }
  .campaignbox {
    width: 100%;
    padding: 20px;
  }
}
</style>
