<template>
   <div class="bg-blue-top-curve-desktop"></div>
     <div class="bg-blue">
      <div class="mobile-curve">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-center text-pg-blue fw-bold">          
            How It Works
        </h1>
      </div>
    </div>
  </div>


  <div class="container">
    <div class="row">
      <div class="col-md-4 text-center">
        <div class="htwstep">
          <img
            src="https://snippcheck.blob.core.windows.net/files/PGLocalValues/step1.png"
            alt="shop"
            class="img-fluid"
          />
          <h3 class="fw-bold text-pg-blue mb-3 h5">Shop P&G products</h3>
          <p>
            Buy participating P&G <br> products from a <br>local retailer in-store or online.
          </p>
        </div>
      </div>
      <div class="col-md-4 text-center">
        <div class="htwstep">
          <img
            src="https://snippcheck.blob.core.windows.net/files/PGLocalValues/step2.png"
            alt="upload"
            class="img-fluid"
          />
          <h3 class="fw-bold text-pg-blue mb-3 h5">Upload your receipt</h3>
         <p>Return to P&G Local Values<br>  Rewards to find your<br> participating promotion and<br>upload your receipt by<br>
following the redemption <br>process.</p>
        </div>
      </div>
      <div class="col-md-4 text-center">
        <div class="htwstep">
          <img
            src="https://snippcheck.blob.core.windows.net/files/PGLocalValues/step3.png"
            alt="send"
            class="img-fluid"
          />
          <h3 class="fw-bold text-pg-blue mb-3 h5">Get a reward</h3>
          <p>If your receipt passess<br>
validation, you will receive the<br>
participating program reward,<br>
all just for shopping your<br>
favourite household items!</p>
        </div>
      </div>
    </div>
  </div>
</div>

  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <hr class="bgline">
      </div>
    </div>
  </div>
  <FaqItem></FaqItem>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
       <h5 class="fw-bold text-pg-blue mb-3 text-center mb-5 helpemail">Got a question we didn't answer? <br>Email <a href="mailto:help@pglocalvalues.com">help@pglocalvalues.com</a></h5>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import FaqItem from './FaqItem.vue';
export default {
  components: {
    FaqItem,
  },
};
</script>

<style scoped>

.helpemail a{color: #003da6; text-decoration: none;}
.helpemail a:hover{color: #003da6; text-decoration: underline;}
@media screen and (max-width: 767px) {
  .bgline{display: none;}
}
</style>
